import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

function loadView(viewName) {
  return () => import(`@/views/${viewName}.vue`);
}

const routes = [
  {
    path: '/reports',
    name: 'reports',
    component: loadView('Home'),
    redirect: { name: 'EscortReport' },
    children: [
      {
        path: 'logs',
        name: 'logs',
        component: loadView('Logs')
      },
      { path: '/users', name: 'users', component: loadView('User') },
      {
        path: 'escort',
        name: 'EscortReport',
        component: loadView('ReportEscortPage')
      },
      {
        path: 'reference',
        name: 'ReferenceReport',
        component: loadView('ReportReferencePage')
      },
      {
        path: 'food',
        name: 'FoodReport',
        component: loadView('ReportFoodPage')
      }
    ]
  },
  {
    path: '/',
    name: 'home',
    component: loadView('Home'),
    redirect: { name: 'reports' }
  },
  {
    path: '/login',
    name: 'login',
    component: loadView('UserLogin')
  },
  {
    path: '/404',
    component: loadView('NotFound'),
    name: '404',
    props: true
  },
  {
    path: '/network-issue',
    component: loadView('NetworkIssue'),
    name: 'network-issue'
  },
  {
    path: '/oferta',
    component: loadView('Oferta'),
    name: 'oferta'
  },
  {
    path: '*',
    redirect: { name: '404', params: { resource: 'page' } }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
