import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import notification from './modules/notification';
import log from './modules/log';
import reference from './modules/reference';
import check from './modules/check';
import escort from './modules/escort';
import food from './modules/food';
import user from './modules/user';

//import modules from './modules'

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    notification,
    log,
    reference,
    food,
    check,
    escort,
    user
  }
});
