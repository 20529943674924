export default {
  orders: [],
  isOrdersLoading: false,
  isOrdersError: false,

  isOrderActionsLoading: false,
  isOrderActionsError: false,
  orderActions: '',
  orderActionsErrorMessage: '',

  isOrderLoading: false,
  isOrderError: false,
  orderErrorMessage: '',
  orderById: null
};
