import api from './api';
export default {
  getOrderData(orderId) {
    return api.get('/orders/escort/' + orderId);
  },
  getOrderActions(orderId) {
    return api.get('/orders/escort/' + orderId + '/actions');
  },
  getOrdersForDates(queryParams) {
    return api.get(`/orders/escort`, {
      params: queryParams
    });
  },
  downloadOrdersExcel(queryParams) {
    return api.get(`/orders/escort/download`, {
      params: queryParams,
      responseType: 'blob'
    });
  }
};
